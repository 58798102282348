import React from 'react';

const Venice = () => {
  return (
    <div className="bg-black text-white font-sans min-h-screen flex flex-col justify-between">
      {/* Header */}
      <div className="flex justify-center py-4">
        <a href="https://eu.em.pro.sony/v0000p480ggKE0b5B000000" className="flex justify-center">
          <img src="https://eu.info.pro.sony/rs/063-EBK-070/images/Sony%20logo%20white.png" alt="SONY" className="w-24 h-12" />
        </a>
      </div>

      {/* Image */}
      <div className="flex justify-center flex-grow items-center">
        <img src="https://eu.info.pro.sony/rs/063-EBK-070/images/VENICE_ESP.png" alt="VENICE_ESP.png" className="max-w-full lg:max-w-3xl object-contain" />
      </div>

      {/* Content */}
      <div className="px-4 lg:px-20 py-8 text-center">
        <h1 className="text-2xl mb-4">Hola, Buenas Noticias!</h1>
        <p className="text-lg mb-4">VENICE YA SE PUEDE REPARAR EN ESPAÑA</p>
        <p className="text-base mb-4">
          SONSUR ya es Partner de Servicio Técnico oficial certificado para VENICE, y ha invertido en stock de piezas de repuesto para asegurar una reparación rápida.
          Detalles de contacto:
        </p>
        <address className="text-base mb-4 not-italic">
          Calle Embajadores 150, 28045 Madrid<br />
          +34 914748251<br />
          <a href="mailto:juan.rivas@sonsur.com" className="text-blue-400">juan.rivas@sonsur.com</a><br />
          Juan Rivas
        </address>
        <p className="text-lg mb-4">ACCESO DIRECTO A INGENIEROS DE SONY</p>
        <p className="text-base mb-4">
          Adicionalmente hemos mejorado nuestro Servicio de Sony donde todo dueño de una VENICE tendrá acceso directo a un Ingeniero de Sony (Lunes-Viernes de 9:00-17:00) mediante este teléfono:<br />
          +34 912768732<br />
          Pin 12321
        </p>
        <p className="text-base mb-4">
          El Pin te identificará como dueño de VENICE en España, se te pedirá el número de serie de VENICE.
        </p>
        <p className="text-base mb-4">
          Echa un vistazo a las producciones que está siendo grabadas con VENICE en España y las opiniones de los DoP.
        </p>
      </div>

      {/* Button */}
      <div className="flex justify-center py-4">
        <button className="bg-sonsurAccent-light text-white text-center mx-10 active:bg-sonsurAccent-dark text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg hover:bg-sonsurAccent-dark outline-none focus:outline-none"
          type="button" style={{ transition: "all 0.15s ease 0s" }}>
          Shot on VENICE
        </button>
      </div>

      {/* Footer */}
      <div className="px-4 lg:px-20 py-8 text-center">
        <p className="mb-4">Comparte las producciones rodadas con VENICE</p>
        <p className="text-lg mb-4">#ShotonVENICE #SonyCine</p>
        <p className="text-base mb-4">Alizé Amrani<br />
          Regional Marketing Manager, South Europe<br />
          Professional Solutions Europe<br />
          D: +33 155903329 | M: +33 620845505
        </p>
      </div>
    </div>
  );
};

export default Venice;
